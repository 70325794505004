<template>
	<div>
		<div class="flex">
			<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

				<h1 class="text-2xl font-bold mb-5">Add a Company</h1>

				<form @submit.prevent="submitCompany" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="company">
					
					<template v-if="getFormError(form)">
						<div class="alert alert-red-soft mb-10">
							<span class="alert-icon">!</span>
							<span>{{ getFormError(form) }}</span>
						</div>
					</template>

					<form-group
						left-icon="business-outline"
						name="company_name"
						v-model="form.data.company_name.value"
						:form="form"
					>
						Company Name
					</form-group>

					<div class="grid grid-cols-1 md:grid-cols-2 md:gap-8">
						<div class="col-span-1">
							<form-group
								type="select"
								:options="tiers"
								left-icon="star-outline"
								name="tier"
								v-model="form.data.tier.value"
								:form="form"
							>
								Company Tier
							</form-group>
						</div>
						<div class="col-span-1">
							<form-group
								left-icon="mail-open-outline"
								name="email_domain"
								v-model="form.data.email_domain.value"
								:form="form">
								Email Domain
							</form-group>
						</div>
					</div>

					<form-group
						left-icon="person-outline"
						name="admin_name"
						v-model="form.data.admin_name.value"
						:form="form">
						HR Name
					</form-group>
	

					<form-group
						left-icon="mail-outline"
						name="admin_email"
						v-model="form.data.admin_email.value"
						:form="form"
					>
						Company Official Email Address
					</form-group>
						
				

					<form-group
						left-icon="mail-outline"
						name="admin_personal_email"
						v-model="form.data.admin_personal_email.value"
						:form="form"
						underText="E.g johndoe@credpal.com"
					>
						HR Officail Email Address
					</form-group>
						<!-- <small>E.g johndoe@credpal.com</small> -->

					<div class="grid grid-cols-1 md:grid-cols-2 md:gap-8">
						<div class="col-span-1">
							<form-group
								left-icon="call-outline"
								name="admin_phone"
								v-model="form.data.admin_phone.value"
								:form="form"
							>
								HR Phone Number
							</form-group>
						</div>
						<!-- <div class="col-span-1">
							<div class="flex flex-row items-center mb-10">
								<form-group
									type="number"
									class="mb-0"
									left-icon="cellular-outline"
									name="interest_rate"
									v-model="form.data.interest_rate.value"
									:form="form"
								>
									Interest
								</form-group>
								<div class="text-sm text-gray-500">%/Month</div>
							</div>
						</div> -->
					</div>

					<div class="text-right">
						<button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading">
							<span v-if="form.loading">Submitting...</span>
							<span v-else>Next</span>
						</button>
					</div>

				</form>

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				{{ companyName }} created successfully
			</div>

			<router-link :to="{name: 'dashboard'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				Go to Dashboard
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Onboarding a company.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Now we're ready to register a company.</p>
					<p class="mb-2">Fill the form below, submit and they will be notified to log in to the system.</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				company: null,
				checkmark: require('@/assets/checkmark-base.svg'),
				modalKey: 'company-new-help',
				form: this.$options.basicForm([
					'company_name',
					'tier',
					'email_domain',
					// {
					// 	name: 'interest_rate',
					// 	rules: 'required|number|min:0'
					// },
					{
						name: 'admin_name',
						rules: 'required|name'
					},
					{
						name: 'admin_email',
						rules: 'required|email'
					},
					{
						name: 'admin_personal_email',
						rules: 'required|email'
					},
					{
						name: 'admin_phone',
						rules: 'required|phone'
					}
				]),
				tiers: [
					{ title: 'Tier 1', value: 1 },
					{ title: 'Tier 2', value: 2 },
					{ title: 'Tier 3', value: 3 }
				]
			}
		},
		computed: {
			companyName() {
				return this.company?.name;
			}
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		methods: {
			async submitCompany() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = false;
				this.form.loading = true;

				await this.$post({
					url: `${this.$baseurl}/companies/create`,
					data: this.getFormData(),
					headers: this.headers,
					success: response => {
						this.company = response.data.data;
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
					},
					error: error => {
						this.form.error = error;

						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			}
		}
	}
</script>